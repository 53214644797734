
// ----------------------------------------------------------------------------
// Font
// ----------------------------------------------------------------------------

@font-face {
    font-family: "Pretendard Variable";
    font-weight: 45 920;
    font-style: normal;
    font-display: swap;
    src: local("Pretendard Variable"),
        url("../resource/fonts/PretendardVariable.woff2") format("woff2-variations");
}

// ----------------------------------------------------------------------------
// Shadow
// ----------------------------------------------------------------------------

.pz-board-shadow {
    /* shadow-md : box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
    box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 0.1);
}
  
.pz-card-shadow {
    /* shadow-md : box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
    box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 0.1);
    &:hover {
      box-shadow: 2px 2px 5px 2px rgb(0 0 0 / 0.2);
    }
}
  
.pz-qr-shadow {
    /* shadow-md : box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
    box-shadow: 6px 6px 10px 4px rgb(0 0 0 / 0.10);
    &:hover {
      box-shadow: 6px 6px 10px 4px rgb(0 0 0 / 0.13);
    }
}

.pz-em-shadow {
    /* shadow-md : offx, offy, blur, spread, color */
    box-shadow: 0px -6px 12px 4px rgb(0 0 0 / 0.10);
}

.pz-fab-shadow {
    /* shadow-md : offx, offy, blur, spread, color */
    box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 0.30);
    &:hover {
        box-shadow: 0px 4px 6px 2px rgb(0 0 0 / 0.40);
    }
}

.pz-fab-shadow-half {
    /* shadow-md : offx, offy, blur, spread, color */
    box-shadow: 0px 2px 3px 1px rgb(0 0 0 / 0.40);
    &:hover {
        box-shadow: 0px 2px 3px 1px rgb(0 0 0 / 0.50);
    }
}


// ----------------------------------------------------------------------------
// Animation
// ----------------------------------------------------------------------------

@keyframes heartbeat {
    0% { transform: scale(1.00); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1.00); }
}

@keyframes heartbeat2 {
    0% { transform: scale(1.00); }
    50% { transform: scale(1.10); }
    100% { transform: scale(1.00); }
}

.heartbeat {
    animation: heartbeat 1s infinite;
}

.heartbeat2 {
    animation: heartbeat2 1s infinite;
}

.heart {
    color: green;
    opacity: 0.3;
    cursor: pointer;
}
  
.heart:hover {
    animation: heartbeat 1s infinite;
    fill: red;
    color: red;
    opacity: 1.0;
    transition: opacity 500ms;
}

@keyframes pulseboundary {
    0% {
        border-style: solid;
        border-width: 2px;
        box-shadow: 0 0 4px -4px gray;
    }
    50% {
        border-style: solid;
        border-width: 2px;
        box-shadow: 0 0 4px +4px gray;
    }
    100% {
        border-style: solid;
        border-width: 2px;
        box-shadow: 0 0 4px -4px gray;
    }
}

.pulseboundary {
    animation: pulseboundary 2s infinite;
}

// ----------------------------------------------------------------------------
// Reset
// ----------------------------------------------------------------------------

* {
    padding: 0;
    margin: 0;
}

:root {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    --toastify-toast-width: 360px;
    font-size: calc(7px + min(0.70vw, 9px));
    // font-size: calc(15px + 0.390625vw);
}

// body > .skiptranslate {
//     display: none;
// }
// .goog-te-banner-frame.skiptranslate {
//     display: none !important;
// }
/* Hide the Google Translate iframe and its container */
.goog-te-banner-frame.skiptranslate, .goog-te-gadget-icon {    
    display: none !important;
}

body {
    font-family: "Pretendard Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    top: 0px !important;
    color: black;
    background-color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
    font-family: "Pretendard Variable";
    font-size: 1.875rem;
    font-weight: 800;
    margin: 0;
}
h2 {
    font-family: "Pretendard Variable";
    font-size: 1.5rem;
    font-weight: 750;
    margin: 0;
}
h3 {
    font-family: "Pretendard Variable";
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
}
h4 {
    font-family: "Pretendard Variable";
    font-size: 1.125rem;
    font-weight: 650;
    margin: 0;
}
h5 {
    font-family: "Pretendard Variable";
    font-size: min(1rem, 17px);
    font-weight: 600;
    margin: 0;
}
h6 {
    font-family: "Pretendard Variable";
    font-size: min(0.875rem, 16px);
    font-weight: 500;
    margin: 0;
}
p,
label,
span {
    font-family: "Pretendard Variable";
    font-size: min(1.0rem, 16px);
    font-weight: 400;
    margin: 0;
}
div,
table,
thead,
tbody,
tr,
td,
th,
a,
select,
button,
label,
input,
textarea {
    font-family: "Pretendard Variable";
    font-weight: 400;
    margin: 0;
}

// ----------------------------------------------------------------------------
// Variables
// ----------------------------------------------------------------------------

// colors
$gray-100: #f8fafc;
$gray-200: #f1f5f9;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$blue-100: #007bff;
$blue-200: #0068d6;
$blue-300: #0054ad;
$blue-400: #00438b;
$blue-500: #003975;
$blue-600: #002b58;
$skyblue-100: #eff5fd;
$skyblue-200: #e1eafa;
$skyblue-300: #b8d7ff;
$skyblue-400: #94c3f0;
$skyblue-500: #7ec1ff;
$skyblue-600: #4096ff;
$purple-100: #6442c1;
$pink-100: #f16886;
$red-100: #da1f31;

// shadows
$shadow-100: 0 10px 20px 0 rgba(black, 0.05);

// border radius
$border-radius1: 3px;
$border-radius2: 5px;
$border-radius3: 10px;
$border-radius4: 20px;
$border-radius5: 30px;

$bg-user-layout: linear-gradient(45deg, #3a3985, #3499ff);

// @import "./reset";
// @import "./variables";
